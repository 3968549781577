import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/all";

gsap.registerPlugin(ScrollToPlugin);

const topScroll = () => {
  gsap.to(window, {
    duration: 1.5,
    scrollTo: 0,
  })
}


export const handleToTop = () => {
  const toTop = document.querySelector(`#lift`)

  if (toTop) {
    toTop.addEventListener('click', topScroll)
  }
}
